import { watch, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'

export default function overlayTimeout(view) {
    let overlayTimer = null

    const clearOverlayTimer = () => {
        if (overlayTimer) {
            clearTimeout(overlayTimer)
            overlayTimer = null
        } else {
            // Fecha o Swal se estiver aberto
            if (Swal.isVisible()) {
                Swal.close()
            }
        }
    }

    const startOverlayTimer = () => {
        clearOverlayTimer()

        overlayTimer = setTimeout(() => {
            Swal.fire({
                title: 'Sistema Lento',
                text: 'O VeltaCar está demorando mais que o normal para responder. Prezamos pela velocidade e isso não deveria acontecer. O que você deseja fazer?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Recarregar a página',
                cancelButtonText: 'Esperar mais um pouco',
                reverseButtons: true,
                customClass: {
                    confirmButton: 'btn btn-primary ml-1 my-2',
                    cancelButton: 'btn btn-outline-warning',
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    store.commit(`${view}/setOverlay`, false);

                    document.location.href = document.location.href;
                } else {
                    startOverlayTimer()
                }
            })
        }, 25000)
    }

    watch(
        () => store.state[view]?.overlay,
        (newValue) => {
            if (newValue) {
                startOverlayTimer()
            } else {
                clearOverlayTimer()
            }
        }
    )

    // Limpa o timer quando o componente é destruído
    onUnmounted(() => {
        clearOverlayTimer()
    })
}
