import {onUnmounted} from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import overlayTimeout from './overlayTimeout'

export default function storeModule(view) {
    // Inicializa o monitoramento do overlay
    overlayTimeout(view)

    if (!store.hasModule(view)) {
        store.registerModule(view, {
            namespaced: true,
            state: {
                action: '',
                id: '',
                overlay: false,
                dados: [],
                options: [],
                message: '',
            },
            mutations: {
                setDados(state, dados) {
                    state.dados = dados;
                },
                setAction(state, action) {
                    state.action = action.substr(0, 1);
                },
                setId(state, id) {
                    state.id = id;
                },
                setMessage(state, message) {
                    state.message = message;
                },
                setOverlay(state, boolean) {
                    state.overlay = boolean;
                },
                setOptions(state, options) {
                    state.options = options;
                }
            },
            getters: {
                getOptions(state) {
                    return state.options
                },
                getDisabled(state) {
                    return state.action === 'r';
                }
            },
            actions: {
                fetchAll(ctx, params = {}) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/fetchAll`, { params }).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                fetch({ state }) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/fetch`, {
                            params: {
                                id: state.id
                            }
                        }).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                fetchOptions({ state }) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/fetchOptions`, {
                            params: {
                                id: state.id
                            }
                        }).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                store({ state }) {
                    return new Promise((resolve, reject) => {
                        axios.post(`/api/${view}/store`, state.dados).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                update({ state }) {
                    return new Promise((resolve, reject) => {
                        axios.post(`/api/${view}/update`, state.dados).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                destroy(ctx, id) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/destroy`, {
                            params: {
                                id
                            }
                        }).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                }
            },
        })
    }

    onUnmounted(() => {
        if (store.hasModule(view) && router.currentRoute.meta.navActiveLink !== view) {
            store.unregisterModule(view)
        }
    })
}
