import Vue from 'vue'
import {ModalPlugin, ToastPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueTheMask from 'vue-the-mask'
import MoneySpinner from 'v-money-spinner'
import * as Sentry from "@sentry/vue"

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/vee-validate'

Vue.use(MoneySpinner)
Vue.use(VueTheMask)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
import '@core/scss/core.scss'

// import assets styles
import '@/assets/scss/style.scss'

Vue.config.productionTip = false


Sentry.init({
    Vue,
    dsn: "https://3d0f8ca08202a761cd214482d6bc41fb@o4508795680063488.ingest.us.sentry.io/4508795694088192",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/veltacar\.com\.br/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

Vue.directive('can', {
    inserted(el, binding, vnode, old) {
        const permissoes = store.getters['permissoes/getPermissoes'];
        const permissao_sigla = binding.value[0];
        const permissao_acao = binding.value[1];

        if (!permissoes['ADMIN'] && (typeof permissoes[permissao_sigla] == 'undefined' || typeof permissoes[permissao_sigla][permissao_acao] == 'undefined' || !permissoes[permissao_sigla][permissao_acao])) {
            vnode.elm.parentElement.removeChild(vnode.elm)
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
