<template>
    <div
        id="app"
        class="h-100"
        :class="[skinClasses]"
    >
        <warning-download-app />
        <component :is="layout" />
    </div>
</template>

<script>

import {$themeBreakpoints, $themeColors, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import {useCssVar, useWindowSize} from '@vueuse/core'
import store from '@/store'
import WarningDownloadApp from "@/layouts/components/WarningDownloadApp.vue";
import formStoreModule from '@/velta/components/crud/mixins/storeModule'
import filterStoreModule from '@/velta/components/filter/mixins/storeModule'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
    components: {
        WarningDownloadApp,
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
    },
    computed: {
        layout() {
            return (this.$route.meta.layout === 'full') ? 'layout-full' : `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
    },
    beforeCreate() {
        const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
        }
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
        }

        const { isRTL } = $themeConfig.layout
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    },
    setup() {
        const { skinClasses } = useAppConfig()

        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: 'Vue-Toastification__fade',
        })

        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const { width: windowWidth } = useWindowSize()
        watch(windowWidth, val => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        try {
            const formModules = [
                "controlecaixacategoria",
                "itemchecklistmodelo",
                "cliente",
                "convenio",
                "formasdepagamentos",
                "funcionario",
                "empresaCliente",
                "modalidade",
                "perfil",
                "produto",
                "servico",
                "usuario",
                "vaga",
                "controlecaixa",
                "orcamento",
                "venda",
                "recibo",
                "adminveltaposts"
            ]

            formModules.forEach(view => {
                try {
                    formStoreModule(view)
                } catch (error) {
                    console.error(`Erro ao registrar módulo de form ${view}:`, error)
                }
            })

            const filterModules = [
                "relatoriovenda",
                "aniversariante",
                "caixa",
                "relatoriocliente",
                "relatorioestoque",
                "servicorealizado",
                "servicoposvendacliente"
            ]

            filterModules.forEach(view => {
                try {
                    filterStoreModule(view)
                } catch (error) {
                    console.error(`Erro ao registrar módulo de filter ${view}:`, error)
                }
            })
        } catch (error) {
            console.error('Erro ao registrar módulos:', error)
        }

        return {
            skinClasses,
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
