import {onUnmounted} from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import router from '@/router'

export default function storeModule(view) {
    if (!store.hasModule(view)) {
        store.registerModule(view, {
            namespaced: true,
            state: {
                dados: {},
                options: [],
                submited: false,
            },
            mutations: {
                setOptions(state, options) {
                    state.options = options;
                },
                setSubmited(state, submited) {
                    state.submited = submited
                }
            },
            getters: {
                getOptions(state) {
                    return state.options
                }
            },
            actions: {
                fetchOptions({ state }) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/fetchOptions`).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
                fetchAll({ state },  params = {}) {
                    return new Promise((resolve, reject) => {
                        axios.get(`/api/${view}/fetchAll`, {
                            params: {
                                ...state.dados,
                                ...params
                            }
                        }).then((response) => resolve(response)).catch((error) => reject(error))
                    })
                },
            },
        })
    }

    onUnmounted(() => {
        if (store.hasModule(view) && router.currentRoute.meta.navActiveLink !== view) {
            store.unregisterModule(view)
        }
    })
}
